import React, { Component, useState } from 'react'
import { Container, Row, Col, Card, Button, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';


const Project = () => {
    const [showShotMaps, setShowShotMaps] = useState(false);
    const [showGoalDiff, setShowGoalDiff] = useState(false);
    const [showPolar, setShowPolar] = useState(false);
    const [showSleeper, setShowSleeper] = useState(false);
    const [showWWJD, setShowWWJD] = useState(false);
    const [showLC, setShowLC] = useState(false);
    return (
        <div className='container'>
            <h1 className="page-header">Projects</h1>
            <Row xs={1} md={2} className="g-4">
                <Col>
                                    <Card className='mx-auto h-100 shadow p-3 mb-5 bg-white rounded'>
                        <Card.Img className='card-images' variant="top" src="./xg.png" />
                        <Card.Body>
                            <Card.Title className='card-font' >NHL Expected Goals Model</Card.Title>
                            <Card.Text className='card-font' >
                                Machine Learning program to predict goals.
                            </Card.Text>
                            <div className="col text-center">
                                <Button variant="dark" onClick={() => setShowSleeper(true)}>Learn More</Button>
                                <Modal show={showSleeper} onHide={() => setShowSleeper(false)} size="lg">
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            <h5>NHL Expected Goals Model</h5>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='container'>
                                            <a target="_blank" rel="noopener noreferrer" href="https://github.com/ztandrews/NHLExpectedGoals">
                                                <img className='card-icons' src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png"></img>
                                            </a>
                                            <h5 className='left'>About</h5>
                                            <h6>&emsp;This is a Python Machine Learning project that I started building as my final project for my machine learning course in graduate school at Monmouth
                                                University, and have been continuing since. The goal of the project was to create an expected goals model for the NHL. Expected Goals are the probability that a given shot
                                                results in a goal, and are an incredibly useful hockey statistic. I wanted to combine my love for hockey with my machine learning knowledge, and thus this project was created.
                                            </h6>
                                            <h5 className='left'>Implementation</h5>
                                            <h6>&emsp;Before training the model, lots of data preprocessing and cleaning was applied to the data set. The data is sourced from a Python module called hockey-scraper, which is a Python package that scrapes NHL play-by-play data. 
                                                With this data, I utilized one-hot encoding for categorical features and made new numeric features to 
                                                give the model informative and useful data to train on. The model was trained on over 700,000 NHL shots and used an XGBoost Classifier to 
                                                predict the probability that a shot resulted in a goal. The model trained well, producing a .766 AUC score when applied to 
                                                the testing data.
                                            </h6>
                                        <h5 className='left'>Applications</h5>
                                            <h6>&emsp;The model is now used in production to predict every NHL shot's xG value. Every day, after all NHL games complete, I have an automated script that runs and scrapes each games play-by-play data, cleans the data, applies the model,
                                                and adds to it to a local Postgres database so I can have up to data data.
                                            </h6>
                                            <h6>&emsp;I utilize this model and it's data to run a hockey statistics Twitter account, @StatsByZach. On there, I post daily game breakdowns, charts, and other general ad-hoc stats; some of which are shown below.
                                            </h6>
                                            <h5 className='left'>5v5 xGF/60 vs. xGA/60</h5>
                                            <img className='img-fluid' src="./60.png"></img>
                                            <h6>&emsp;This is a chart of each NHL teams 5v5 xGF/60 vs. xGA/60 through January 31st of the 2022-23 season. This shows us which teams are performing well or poorly so far. A high xGF/60 means that a team excels at driving offense, and a low xGF/60 means
                                                they are struggling to generate offense. 
                                                A low xGA/60 means that a team excels at suppressing shots (playing defense), and a high xGA/60 means a team is struggling to suppress shots. The stats are standardized to a per 60 minute basis to account for differences in time on ice of each team.
                                            </h6>
                                            <h5 className='left'>First Ten Minutes of A Game Analysis</h5>
                                            <img className='img-fluid' src="https://pbs.twimg.com/media/FmNef1HWYActmw0?format=png&name=large"></img>
                                            <h6>&emsp; This is a chart that shows us each teams xGF% (ratio of expected goals for to total expected goals) during the first ten minutes of their games. I conducted to a short study to see how poorly The New York Islanders were starting their games. The Islanders went through a time in which they were getting heavily outplayed to start games,
                                                and I wanted to see just how bad it was. I did a brief study, and produced this chart along the way. The chart backed up my claim, as we can see that The Islanders have one of the worst xGF% out of eery team in the league.
                                            </h6>
                                            <h5 className='left'>Goalie Charts</h5>
                                            <img className='img-fluid' src="./goalie.png"></img>
                                            <h6>&emsp;This is a chart I build using Plotly to visualize how goalies perform from different shot angles. The chart is meant to be viewed with the center of the polar chart being the center of the goal crease with 5 equally split bins that are a range of 36 degrees each (from -90 to 90 degrees)
                                                Each bins height is equal to the specific goaltenders goals saved above expected (Fenwick expected goals faced - actual goals allowed) percentile for that specific bin. In the graphic above, we can see that Ilya Sorokin is in high percentiles in each shot angle bin, which shows
                                                he has no noticeable issues in saving shots from certain angles.
                                            </h6>
                                            <h5 className='left'>Single Game xGF% Charts</h5>
                                            <img className='img-fluid' src="./xgfgame.png"></img>
                                            <h6>&emsp;This is a chart that shows a single-game xGF% chart for a team. The purpose of this is to visualize which players had a good game in terms of on-ice xGF%, meaning the ratio of expected goals for to total expected goals while
                                                the specific player was on the ice. Generally, a player with a higher xGF% had a better impact for their team than
                                                a player with a lower one. 
                                            </h6>
                                                <h5 className='left'>Application on Single Game</h5>
                                            <img className='img-fluid' src="./good_data2.png"></img>
                                            <h6>&emsp;This is a graphic built to showcase how the metric can be used at the game level. From these charts, we can see which team generated more offense and the quality of each shot that they took. This chart specifically shows us that
                                                the Canucks outperformed the Islanders in terms of generating offense and converting on scoring chances. 
                                            </h6>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </Card.Body>
                    </Card>
                   
                </Col>
                <Col>
                                <Card className='mx-auto h-100 shadow p-3 mb-5 bg-white rounded'>
                        <Card.Img className='card-images' variant="top" src="./rink.png" />
                        <Card.Body>
                            <Card.Title className='card-font' >NHL Shot Map Animations</Card.Title>
                            <Card.Text className='card-font' >
                                A Python program to animate NHL shots from games.
                            </Card.Text>
                            <div className="col text-center">
                                <Button variant="dark" onClick={() => setShowShotMaps(true)}>Learn More</Button>
                                <Modal show={showShotMaps} onHide={() => setShowShotMaps(false)} size="lg">
                                    <Modal.Header closeButton>
                                        <Modal.Title><h5 className='left'>NHL Shot Map Animations</h5></Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='container'>
                                            <a target="_blank" rel="noopener noreferrer" href="https://github.com/ztandrews/NHLAnimatedShots">
                                                <img className='card-icons' src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png"></img>
                                            </a>
                                            <h5 className='left'>About</h5>
                                            <h6>&emsp;This program shows an animation of when and where shots are taken in an NHL game. Each shot is plotted in order of the when the shot was taken in the game, and the point size is weighted by
                                                it's expected goal value from my NHL Expected Goals Model project. The purpose of the project is so we can easily see at what point each team was applying the most offensive pressure, and what the result of the pressure was. The chart
                                                also shows each teams total goals for and expected goals for at the top of the chart, to give viewers a good idea of who was controlling play at any point in the game.
                                            </h6>
                                            <h5 className='left'>Implementation</h5>
                                            <h6>&emsp;This program was made with Python and a combination of packages. The program gets data from a CSV file that gets output from my NHL Expected Goals Model program that contains critical data about each shot taken. The program
                                                then filters through the data to find the game that the user wants to plot, and creates a list of all shots that are in order of when they were taken in the game. Using MatPlotLib and a module called hockey-rink, each shot is plotted 
                                                in order of when they were taken, and the animation is saved and exported as a GIF file. 
                                            </h6>                                         
                                            <h5 className='left'>Demo</h5>
                                            <img className="img-fluid" src="./game_shots2.gif"></img>
                                            <h6>&emsp;This demo chart shows a game between the Vancouver Canucks and the New York Islanders. By watching the GIF, we can see that Vancouver applied a lot of pressure in the 2nd and 3rd period, and they outperformed New York
                                            by generating about .75 more expected goals than them. The Canucks also won this game by a score of 4-3. 
                                            </h6>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br></br>
            <br></br>
            <Row xs={1} md={2} className="g-4">
                <Col>
                    <Card className='mx-auto h-100 shadow p-3 mb-5 bg-white rounded'>
                        <Card.Img className='card-images' variant="top" src="./charts.png" />
                        <Card.Body>
                            <Card.Title className='card-font' >MLB Polar Charts</Card.Title>
                            <Card.Text className='card-font' >
                                Programs to chart MLB players' stats.
                            </Card.Text>
                            <div className="col text-center">
                                <Button variant="dark" onClick={() => setShowPolar(true)}>Learn More</Button>
                                <Modal show={showPolar} onHide={() => setShowPolar(false)} size="lg">
                                    <Modal.Header closeButton>
                                        <Modal.Title><h5>MLB Polar Charts</h5></Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='container'>
                                            <a target="_blank" rel="noopener noreferrer" href="https://github.com/ztandrews/MLBHitterZScore">
                                                <img className='card-icons' src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png"></img>
                                            </a>
                                            <h5 className='left'>About</h5>
                                            <h6>&emsp;This is a Python project that plots MLB hitters and pitchers stats on a polar chart, relative to league average for each
                                                category. The purpose of this program was to create a one-stop-shop visualization for an MLB player that tells you *most* of the story of
                                                how they are performing in a given year. By charting players Z-Scores for important statistics, we can see how they are performing
                                                in each category relative to league average qualified players.
                                            </h6>
                                            <h5 className='left'>Implementation</h5>
                                            <h6>
                                                &emsp;This program was written in Python and the data is from Baseball Savant. I utilized Pandas to work with the data in an easy way, and Matplotlib to chart
                                                the given player's Z-Scores for each statistic. Below are two example outputs from the programs.
                                            </h6>
                                            <h5 className='left'>A Good Hitter, Visualized</h5>
                                            <img className='img-fluid' src="https://user-images.githubusercontent.com/68918006/114966410-7d2b0900-9e40-11eb-84df-f44418d7dffd.png"></img>
                                            <h5 className='left'>A Bad Hitter, Visualized</h5>
                                            <img className='img-fluid' src="https://user-images.githubusercontent.com/68918006/114966460-97fd7d80-9e40-11eb-9ba2-f2c366ebe1d6.png"></img>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                                    <Card className='mx-auto h-100 shadow p-3 mb-5 bg-white rounded'>
                        <Card.Img className='card-images' variant="top" src="./tasks.png" />
                        <Card.Body>
                            <Card.Title className='card-font' >Smart Time-Planner App</Card.Title>
                            <Card.Text className='card-font' >
                                A ReactJS smart time-management app.
                            </Card.Text>
                            <div className="col text-center">
                                <Button variant="dark" onClick={() => setShowLC(true)}>Learn More</Button>
                                <Modal show={showLC} onHide={() => setShowLC(false)} size="lg">
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            <h5>Smart Time-Planner App</h5>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='container'>
                                             <a target="_blank" rel="noopener noreferrer" href="https://github.com/ztandrews/Chasy">
                                                <img className='card-icons' src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png"></img>
                                            </a>
                                            <h5 className='left'>About</h5>
                                            <h6>&emsp;This project is a web-based time management application that uses an algorithm to plan out which 
                                                tasks to do on a given day. Users specify how much free time they have per day, and then they upload tasks they have to complete and 
                                                specify when they need it done by. The program will then return which tasks the user should prioritize on the current day. Taks that are 
                                                due sooner and have a higher importance than others will have a higher priority than other tasks. This was a project done for the senior project course at Monmouth, and I worked on it with three of my peers. My main roles in the project were 
                                                project manager and back end architect, but I also did some ReactJS design principle work.
                                                </h6>
                                                <h5 className='left'>About</h5>
                                                <h6>
                                                &emsp;This project was built with the FARM Stack, which is Fast API, ReactJS, and MongoDB.
                                            </h6>
                                            <h5 className='left'>Home Screen</h5>
                                            <img className='img-fluid' src="./tasks.png"></img>
                                            <h5 className='left'>Edit Task Screen</h5>
                                            <img className='img-fluid' src="./edit.png"></img>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br></br>
            <Row xs={1} md={2} className="g-4">
                <Col>
                    <Card className='mx-auto h-100 shadow p-3 mb-5 bg-white rounded'>
                        <Card.Img className='card-images' variant="top" src="./wwjd.jpg" />
                        <Card.Body>
                            <Card.Title className='card-font' >Fantasy Football Team Website</Card.Title>
                            <Card.Text className='card-font' >
                                A ReactJS website for my fantasy football team.
                            </Card.Text>
                            <div className="col text-center">
                                <Button variant="dark" onClick={() => setShowWWJD(true)}>Learn More</Button>
                                <Modal show={showWWJD} onHide={() => setShowWWJD(false)} size="lg">
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            <h5>Fantasy Football Team Website</h5>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='container'>
                                            <h5 className='left'>About</h5>
                                            <h6>
                                                &emsp;This project was my first full-stack React application. After weeks of learning React, I decided the best way
                                                for me to fully grasp all of the concepts was to build out a fully functional application, and decided I could make it
                                                fun by creating a website for my dynasty fantasy football team. The website includes an auto-refreshing scoreboard for the current
                                                week, an articles section where I write some articles about the team, and an auto-updating roster section.<br></br>
                                            </h6>
                                            <h5 className='left'>Implementation</h5>
                                            <h6>
                                                &emsp;This project was built with a ReactJS front-end Firebase for storage and hosting. Firebase offered me
                                                a free, easy to use system that I decided would be the best for the app. I used Python and the Sleeper API to create
                                                batch processes to populate the Firebase databases, which the React application then reads and presents the
                                                data. Below are a few screenshots of the website.
                                            </h6>
                                            <h5 className='left'>Home Page</h5>
                                            <img className='img-fluid' src="./wwjd6.jpg"></img>
                                            <h5 className='left'>Social Page</h5>
                                            <img className='img-fluid' src="./wjd2.jpg"></img>
                                            <h5 className='left'>Roster Page</h5>
                                            <img className='img-fluid' src="./wwjd3.jpg"></img>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                                        <Card className='mx-auto h-100 shadow p-3 mb-5 bg-white rounded'>
                        <Card.Img className='card-images' variant="top" src="./diff.png" />
                        <Card.Body>
                            <Card.Title className='card-font' >NHL Goal Differential</Card.Title>
                            <Card.Text className='card-font' >
                                A program to chart NHL teams goal differentials.
                            </Card.Text>
                            <div className="col text-center">
                                <Button variant="dark" onClick={() => setShowGoalDiff(true)}>Learn More</Button>
                                <Modal show={showGoalDiff} onHide={() => setShowGoalDiff(false)} size="lg">
                                    <Modal.Header closeButton>
                                        <Modal.Title><h5>NHL Goal Differential</h5></Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='container'>
                                            <a target="_blank" rel="noopener noreferrer" href="https://github.com/ztandrews/NHLGoalDifferential">
                                                <img className='card-icons' src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png"></img>
                                            </a>
                                            <h5 className='left'>About</h5>
                                            <h6>&emsp;This is a program written in Python that visualizes an NHL teams goal differential for each game played. If they have a positive goal differential, that means they won
                                                the game and the bar will be green. If they had a negative goal differential, the team lost the game and the bar will be red. This was one of my
                                                first programs I made to experiment with data visualization, so it's main purpose was to re-create the goal differential charts
                                                that appear on Hockey-Reference team pages.<br></br>
                                            </h6>
                                            <h5 className='left'>Implementation</h5>
                                            <h6>
                                                &emsp;This was written in Python and utilized an <a target="_blank" rel="noopener noreferrer" href="https://sportsipy.readthedocs.io/en/latest/nhl.html#sportsipy.nhl.boxscore.BoxscorePlayer.on_ice_shot_attempts_against">NHL Python Package</a> to get game data, as well as Matplotlib to plot each diffierential.
                                            </h6>
                                            <h5 className='left'>Example</h5>
                                            <img className='img-fluid' src="https://user-images.githubusercontent.com/68918006/137642521-cf2c39c2-34c6-4b5a-a126-7456975937d7.png"></img>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Project;
