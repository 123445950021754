import React, { Component } from 'react'
import { Container, Row, Col, Card, Button, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';

export default class Contact extends Component {
    render() {
        return (
            <div className = "container">
                <h1  className = "page-header">Contact</h1>
                    
            <Row xs={1} md={2} className="g-4">
                <Col>
                    <Card className='mx-auto h-100 shadow p-3 mb-5 bg-white rounded'>
                        <Card.Img className='card-images2' variant="top" src="https://img.freepik.com/premium-vector/resumes-cv-application-resume-filling-concept_349999-258.jpg" />
                        <Card.Body>
                            <Card.Title className='card-font' >Resume Request</Card.Title>
                            <Card.Text className='card-font' >
                               <a href="mailto:ztandrews18@sbcglobal.net"><Button variant="dark">Request my Resume</Button></a>
                            </Card.Text>
                            <div class="col text-center">
                              
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <br></br>
                <Col>
                    <Card className='mx-auto h-100 shadow p-3 mb-5 bg-white rounded'>
                        <Card.Img className='card-images2' variant="top" src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png" />
                        <Card.Body>
                            <Card.Title className='card-font' >GitHub</Card.Title>
                            <Card.Text className='card-font' >
                                <a target = "_blank" rel="noopener noreferrer" href="https://github.com/ztandrews"><Button variant="dark">Check out my GitHub</Button></a>
                            </Card.Text>
                            <div class="col text-center">
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br></br>
            <Row xs={1} md={2} className="g-4">
                <Col>
                    <Card className='mx-auto h-100 shadow p-3 mb-5 bg-white rounded'>
                        <Card.Img className='card-images2' variant="top" src = " https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/768px-LinkedIn_logo_initials.png" />
                        <Card.Body>
                            <Card.Title className='card-font' >LinkedIn</Card.Title>
                            <Card.Text className='card-font' >
                                <a target = "_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/zach-andrews-634a16192/"><Button variant="dark">Visit my LinkedIn</Button></a>
                            </Card.Text>
                            <div class="col text-center">
                              
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <br></br>
                <Col>
                    <Card className='mx-auto h-100 shadow p-3 mb-5 bg-white rounded'>
                        <Card.Img className='card-images2' variant="top" src="https://camo.githubusercontent.com/f8eb908793629e627b62fe256f3ca88e6e47f24c4b252e94ad3d0e03be0a3663/68747470733a2f2f63646e342e69636f6e66696e6465722e636f6d2f646174612f69636f6e732f736f6369616c2d6d656469612d6c6f676f732d362f3531322f3131322d676d61696c5f656d61696c5f6d61696c2d3531322e706e67" />
                        <Card.Body>
                            <Card.Title className='card-font' >Email</Card.Title>
                            <Card.Text className='card-font' >
                            <a href="mailto:ztandrews18@sbcglobal.net"><Button variant="dark">Reach out via email</Button></a>
                            </Card.Text>
                            <div class="col text-center">
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            
            </div>
        )
    }
}
