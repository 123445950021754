import React, { Component } from 'react'

export default class Blog extends Component {
    render() {
        return (
            <div className = "container">
                <h1 className = "page-header">Blog</h1>
            </div>
        )
    }
}
